import React from 'react'
import {Link} from 'gatsby'
import config from '../../config'
import './sidebar.css'

const NavItem = ({ linkTo, children }) => (
  <li
    css={{
      boxSizing: 'border-box',
      fontSize: '15px',
      textDecoration: 'none',
      textTransform: 'uppercase',
      letterSpacing: '0.03em',
      position: 'relative',
      transition: 'color .15s ease-out',
      '&:hover': {
        opacity: 0.8,
      },
      display: 'inline-block',
      marginRight: '20px',
      '@media screen and (max-width: 600px)': {
        display: 'block',
        marginRight: '10px',
        marginBottom: 0,
        // fontSize: '20px',
      }
    }}
  >
    <Link to={linkTo} activeStyle={{ color: '#55D3FB' }}>
      {children}
    </Link>
  </li>
)

const Side = (props) => (
  <div 
     {...props}
  >
    <ul css={{
      listStyle: 'none', float: 'right',
      margin: 0,
      display: 'inline-block',
      '@media screen and (max-width: 600px)': {
        // top: config.thumb.height + config.bar.height + 20,
        // left: 20,
        // position: 'fixed',
        // fontWeight: 'bold',
      }
    }}>
      {config.gallery.map(
        it => <NavItem linkTo={it.path} key={it.title}>{it.title}</NavItem>
      )}
    </ul>
  </div>
)
///////////

export default Side