import React from 'react'
import Sidebar from './sidebar'
import { Info } from './header'
import Icon from './hamburgerIcon'

export default class HamburgerBtn extends React.Component {
  constructor(props) {
    super(props)
    this.state = { right: -160 }
    this.showMenu = this.showMenu.bind(this)
  }

  showMenu() {
    this.setState({ right: 0 })
  }
  render() {
    return <div>
      <a
        {...this.props}
        css={{
          color: 'white',
          '&:hover': { color: '#55D3FB' },
          // background-color: #e9e9e9;
          // border-top: 1px solid transparent;
          // border-left: 1px solid transparent;
          // border-right: 1px solid transparent;
        }}
        onClick={this.showMenu}
      >
        {/* <i css={{ '&:before': { content: "☰" }, fontStyle: 'normal', }}></i> */}
        <Icon styles={{
          bmBurgerBars: { backgroundColor: 'lightgray' },
          bmBurgerButton: { width: '30px', height: '20px', }
        }} />
      </a>
      <div css={{
        position: 'fixed',
        zIndex: 10,
        background: 'rgba(27, 27, 27, 0.05)',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: this.state.right < 0 ? 'none' : 'block',
      }}
        onClick={() => this.setState({ right: -160 })}
      >
      </div>
      <div css={{
        position: 'fixed',
        zIndex: 20,
        background: 'rgba(27, 27, 27, 0.75)',
        transition: 'right 0.2s ease',
        top: 0,
        bottom: 0,
        // left: 0,
        right: this.state.right,
        width: 160,
        color: 'white',
      }}

        onClick={() => this.setState({ right: -160 })}
      >
        <div css={{
          margin: 'auto', position: 'absolute',
          top: '50%',
          transform: 'translate(0,-50%)',
          fontSize: 15,
          '& li': { display: 'block !important' },
          '& ul': { float: 'none !important', marginBottom: 20 },
          left: '20',
        }}>
          <Info />
          <Sidebar />
        </div>

      </div>
    </div>
  }
}