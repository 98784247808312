import React from 'react'
import config from '../../config'
import Layout from '../components/layout'

export default () => (
  <Layout>
    <div
      className="bg-wrapper-outer fill"
      css={{
        background: 'lightgrey url(/assets/post.background.jpg)', // any color, eg: #aa3322
        backgroundSize: 'cover'
      }}
    >
      <div
        className="bg-wrapper-inner fill"
        css={{
          backgroundPosition: 'center center',
          backgroundAttachment: 'fixed',
          backgroundImage: 'url(/assets/bg.png)'
        }}
      >
        <div
          className="mainScreen"
          css={{
            animation: `slideInRight ${config.slider.speed}ms ease-in 0s 1 alternate`,
            position: 'absolute',
            paddingTop: config.thumb.height,
            top: 0,
            bottom: 0,
            width: '100%',
            zIndex: 900,
            ...config.post
          }}
        >
          <div
            className="article"
            css={{
              padding: '10%',
              margin: '0',
              height: '100%',
              overflowY: 'scroll',
              boxSizing: 'border-box'
            }}
          >
            <div css={{ maxWidth: '40em', margin: 'auto' }}>
              <h1>Page not found</h1>
              <p>
                Oops! The page you are looking for has been removed or
                relocated.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)
