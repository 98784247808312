import React from 'react'
import {Link} from 'gatsby'
import config from '../../config'

const navItemStyles = {
  boxSizing: 'border-box',
  display: 'inline-block',
  // color: 'white',
  textDecoration: 'none',
  textTransform: 'uppercase',
  letterSpacing: '0.03em',
  position: 'relative',
  top: 0,
}

let Info = (props) => <ul css={{
  listStyle: 'none', float: 'right',
}}>
  {config.navigation.map(
    it => <li
      key={it.title}
      css={{
        display: 'inline-block',
        marginRight: '20px',
        '@media screen and (max-width: 500px)': {
          marginRight: '10px',
        }
      }}
    >
      <Link to={it.path} activeStyle={{ color: '#55D3FB' }}>
        {it.title}
      </Link>
    </li>
  )}
</ul>
const Header = (props) => (
  <div style={{
    backgroundColor: 'black',
    position: 'fixed',
    left: 0,
    top: 0,
    right: 0,
    height: props.height,
    zIndex: -1,
    color: '#636363',
    fontSize: '13px'
  }}
    className='header'
  >
    <header style={{
      position: 'absolute',
      top: '50%',
      transform: 'translate(0,-50%)',
      right: '20px',
    }}>
      <Info />
    </header>
  </div>
)

export default Header
export { Info }
