// import normalize from 'normalize.css'
import React from 'react'
import Footer from './footer'
import Header from './header'
import Top from './top'
import './index.css'
import config from '../../config'
// import {Link} from 'gatsby'
import { Helmet } from 'react-helmet'
import favicon from '../../static/assets/favicon.ico'

const Layout = ({ children }) => {
  return (
    <div
      css={{
        position: 'fixed',
        overflow: 'hidden',
        width: '100%',
        height: '100%'
      }}
      className="bodyLayout"
    >
      <Helmet>
        <link rel="icon" href={favicon} />
        <title>{config.site.title}</title>
      </Helmet>
      <Header height={config.bar.height} />

      <div
        className="mainLayout"
        css={{
          position: 'absolute',
          // width: 'auto',
          // minHeight: '100%',
          right: '0px',
          left: '0px',
          overflow: 'hidden',
          top: config.bar.height,
          bottom: config.bar.height,
          '@media screen and (max-height: 500px)': {
            bottom: 0,
            top: 0
          },
          background: config.site.background
        }}
      >
        <div
          css={{
            position: 'absolute',
            top: 0,
            height: config.thumb.height,
            width: '100%',
            overflow: 'visible'
          }}
          className="topWrapper"
        >
          <Top />
        </div>
        {children}
      </div>
      <Footer />
    </div>
  )
}

export default Layout //({children})=><div>lll{children}</div>
