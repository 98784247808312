import React from 'react'
import {Link} from 'gatsby'
import config from '../../config'
import Sidebar from '../components/sidebar'
import Menu from './hamburger'

export default () => <div className='topBar' css={{
  position: 'absolute',
  // display: 'flex',
  // justifyContent: 'space-between',
  // alignItems: 'center',
  overflow: 'visible',
  left: 0,
  right: 0,
  zIndex: '1100',
  height: config.thumb.height,
  backgroundColor: '#1a1a1a',
  // opacity: 0.5,
  // background: 'rgba(0, 0, 0, 0.5)',
  background: 'rgba(27, 27, 27, 0.5)',
  transition: 'all 1s ease',
  // opacity: 0.9,
  '&:hover': {
    // opacity: '1',
    background: 'rgba(27, 27, 27, 0.75)',
  },
  '@media screen and (max-height: 500px)': {
    background: 'rgba(27, 27, 27, 0.25)',
    '&:hover': {
      background: 'rgba(27, 27, 27, 0.3)',
    },
  }
}}>
  <div css={{
    // top: 0,
    // bottom: 0,
    // margin: 'auto',
    left: 20,
    height: config.logo.height,
    position: 'absolute',
    top: '50%',
    transform: 'translate(0,-50%)',
    overflow: 'visible',
    zIndex: 3000,
  }}>
    <Link to='/'>
      <img src='/assets/logo.png'
        css={{
          height: config.logo.height,
        }}
        alt={config.site.title}
      />
    </Link>
  </div>
  <Sidebar
    css={{
      // backgroundColor: 'red',
      className: 'sidebar',
      margin: 'auto', position: 'absolute',
      top: '50%',
      transform: 'translate(0,-50%)',
      right: 0,
      overflow: 'visible',
      display: 'inline-block',
      '@media screen and (max-width: 600px)': {
        display: 'none',
      },
      '@media screen and (max-height: 500px)': {
        right: 40,
      }
    }}
  />
  <Menu
    css={{
      display: 'none',
      '@media screen and (max-width: 600px) , (max-height: 500px)': {
        position: 'absolute',
        margin: 'auto',
        top: '50%',
        transform: 'translate(0,-50%)',
        right: 10,
        // width: 40,
        display: 'inline-block',
        overflow: 'visible',
      }
    }}
  />
</div>
