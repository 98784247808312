import React from 'react'
// import {Link} from 'gatsby'
import config from '../../config'

const Footer = () => (
  <div css={{
    backgroundColor: 'black',
    position: 'fixed',
    zIndex: -1,
    left: 0,
    bottom: 0,
    right: 0,
    height: config.bar.height,
    color: '#636363',
    fontSize: '13px',
    // textAlign: 'center',
    paddingLeft: '20px'
  }}>
    <span css={{
      position: 'absolute',
      top: '50%',
      transform: 'translate(0,-50%)',
    }}>
     &#9993; email: &nbsp;&nbsp;&nbsp;<a href={`mailto:${config.site.email}`} target="_blank" title='email'>
	 {config.site.email}</a> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span>
    {/* <p style={{float: 'left'}}>
  Phone: <a href='tel:18887549995'>1-888-754-9995</a>
  </p> */}
    {/* <p style={{ float: 'right' }}>© 2021 M-CG.COM</p> */}
  </div>
)
export default Footer
